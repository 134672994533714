<template>
  <en-dialog :model-value="modelValue" width="80%" title="检测单列表" @close="$emit('update:model-value', false)">
    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['VISPRPTS']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      code="VHISTRTFD"
      :data="table.data"
      :loading="table.loading"
      :height="600"
      pagination
      :paging="table.paging"
      :config="table.config"
    >
      <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportDto'] }">
        <button-ajax
          v-if="row.status?.code === 'P' && store.accessRightsHash.VEHICLE_INSPECTION_REPORT_AUDIT"
          link
          :method="table.operation.complete.click"
          :params="row"
        >
          生成报告
        </button-ajax>
        <button-delete
          v-if="row.status?.code === 'P' && store.accessRightsHash.VEHICLE_INSPECTION_REPORT_EDIT"
          :method="table.operation.delete.click"
          :params="row"
        >
          删除
        </button-delete>
        <button-ajax v-if="row.status?.code === 'C'" link :method="table.operation.share.click" :params="row">分享</button-ajax>
      </template>

      <template #VEHICLE_SPEC="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportDto'] }">
        {{ row.vehicle?.vehicleSpec.join(',') }}
      </template>
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
import { h } from 'vue'
import { EnMessageBox } from '@enocloud/components'

export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/inspection/report',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/vehicle/:vehicleId',
            loading: true
          },
          share: {
            action: 'GET /enocloud/common/vehicle/inspection/report/:reportId/share',
            loading: true
          },
          complete: {
            action: 'PUT /enocloud/common/vehicle/inspection/report/:reportId/complete',
            loading: true
          }
        },
        config: {
          PLATE_NO: { prop: 'vehicle.plateNo' },
          OWNER: { prop: 'vehicle.owner.name' },
          CELLPHONE: { prop: 'vehicle.owner.cellphone' },
          VIN: { prop: 'vehicle.vin' },
          VEHICLE_ENGINE_NUMBER: { prop: 'vehicle.engineNumber' },
          INSPECTOR_NAME: { prop: 'inspector.name' }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionReportDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            complete: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionReportDto']) {
                await this.table.complete({ paths: [row.id] })
                this.table.get()
              }
            },
            share: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionReportDto']) {
                const res = await this.table.share({ paths: [row.id] })
                return EnMessageBox({
                  title: `${row.vehicle?.plateNo}的车况报告`,
                  center: true,
                  showConfirmButton: false,
                  message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
                })
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
